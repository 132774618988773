/* COMMON APP CSS */
html {
  font-family: "Almarai", sans-serif;
  font-size: 14px;
}

body {
  font-family: "Almarai", sans-serif;
  font-size: 1.14rem;
  color: #353535;
  font-weight: 300;
  line-height: normal;
  position: relative;
  background-color: #ffffff;
}

.css-t3ipsp-control {
  min-height: 3.8rem !important;
  box-shadow: none !important;
  border: 1px solid #80bdff !important;
  /* border-color: #80bdff !important; */
}

/* html:lang(ar) * {
  text-align: right;
} */
.adon {
  width: 55%;
}

.easy_pickup .modal-header,
.easy_pickup .modal-body,
.easy_pickup .modal-footer {
  padding: 1rem 1rem !important;
}

.color-red {
  color: red !important;
}

i.fas.fa-info {
  cursor: pointer;
  border: 1px solid;
  border-radius: 50%;
  width: 12px;
  padding-left: 4px;
  height: 12px;
  padding-top: 2.5px;
  font-size: 6px;
  position: absolute;
  right: -2px;
  top: -2px;
}

i.fas.fa-info:hover {
  color: #2f7dc9;
}

.margin-right.positions-relative {
  margin-right: 60px !important;
  position: relative;
}

.catgoryproductouter.service_item_removed {
  opacity: 0.5;
  cursor: not-allowed;
}

.catgoryproductouter.service_item_newly_added .color-green {
  color: green;
}

.catgoryproductouter.service_item_newly_added .color-red {
  color: red;
}

.pickup_delivery_button {

  display: flex;
  justify-content: space-between;
}

.modal .modal-header,
.modal .modal-body,
.modal .modal-footer {
  padding: 1.75rem;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.modal .modal-header {
  border-bottom: 1px solid #d7d7d7;
}

.freedeliverytimingdiv {
  margin-top: 12px;
}

.no-corporate-package-tab {
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
  color: rgb(52, 138, 210);
  font-weight: 600;
  font-size: 17px;
  background: #fff;
}

.btn-pickup-time {
  background-color: #2f7dc9;
  border: 1px solid #2f7dc9;
  border-radius: 0.25rem;
  color: #fff;
  font-weight: 500;
  transition: 0.4s ease all;
  padding: 0.6rem 0.8rem;
  cursor: pointer;
  border-radius: 50px;
  outline: initial !important;
  box-shadow: none !important;
  font-size: 0.8rem;
  padding: 0.75rem 1.3rem 0.6rem 1.3rem;
}

.color-green {
  color: green !important;
}

.text-align-right {
  text-align: right;
}

.form-group .css-13cymwt-control {
  min-height: 3.8rem;
  font-family: "Almarai", sans-serif;
  font-size: 1.07rem;
  color: #757575;
  font-weight: 300;
  border-radius: 0px;
  border: 1px solid #e6e6e6;
}

.form-group .css-1xc3v61-indicatorContainer {
  color: black !important;
}

.form-group span.css-1u9des2-indicatorSeparator {
  display: none;
}

.display_flex {
  display: flex;
}

.vendor_image {
  margin: 10px;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.vendor_image img {
  width: 100px;
  height: 75px;
  max-width: revert !important;
}


.display_flex .margin-right {
  margin-right: 25px;
}

.margin-right .form-check-radio {
  margin-left: -1.25rem;
  margin-right: 10px;
}

.justify-content-end {
  justify-content: end !important;
}

.with-100 {
  width: 100%;
}

.with-100.margin {
  margin-bottom: 0px !important;
}

.with-100.margin h6 {
  line-height: 30px;
}

span.btn-reorder.disabled {
  opacity: .65;
  pointer-events: none;
}

.with-100.margin.display_flex.justify-content-space-between button {
  padding: .5rem 2rem !important;
  font-size: 14px !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.justify-content-end .form-group {
  margin-bottom: 0 !important;
}

.justify-content-end .css-13cymwt-control,
.css-t3ipsp-control {
  border-radius: 4px;
}

.align-items-center {
  align-items: center;
}

.adon .col-12 {
  padding-right: 0 !important;
}

html[lang="ar"] {
  direction: rtl;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

/*add a shadow to the scrollbar here*/
::-webkit-scrollbar-track {
  background-color: #ebebeb;
  box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

/*this is the little scrolly dealio in the bar*/
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #b1b1b1;
  height: 3px;
}

/*nobody needs this little scrollbar corner, I mean really, get rid of it haha*/
::-webkit-scrollbar-corner {
  display: none;
  height: 0px;
  width: 0px;
}

/* modal header close x symbol changing */
.Arabic .modal-manual-header button.close:after {
  content: 'يغلق' !important;
  color: #fff;
  font-size: 16px;
  background: #7a7a7a7a;
  padding: 5px 14px;
  border-radius: 15%;
}

.modal-manual-header button.close:after {
  content: 'Close' !important;
  color: #fff;
  font-size: 16px;
  background: #7a7a7a7a;
  padding: 5px 14px;
  border-radius: 15%;
}

.modal-manual-header button span {
  display: none;
}

img {
  max-width: 100%;
  display: inline-block;
}

button,
input,
textarea,
select {
  font-family: "Almarai", sans-serif;
  opacity: 1;
  letter-spacing: 0.0143rem;
}

button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none !important;
  box-shadow: 0rem 0rem 0rem transparent !important;
}

ul,
li {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

a {
  display: inline-block;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  color: #348ad2;
  cursor: pointer;
}

a:hover,
a:focus {
  outline: inherit;
  text-decoration: none;
  color: #348ad2;
}

a:hover {
  color: #2e56bf;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Almarai", sans-serif;
  line-height: normal;
  padding: 0px 0px;
  margin: 0px 0px;
}

.link {
  color: #348ad2 !important;
}

.Arabic .laundries_rates .btn {
  padding: 6px 11px;
  display: inline-block;
}

.alert {
  position: relative;
  z-index: 99;
}

section::after {
  display: block;
  clear: both;
  content: "";
}

.full_container {
  /*max-width: 1920px;*/
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0rem auto;
}

.form-control::-webkit-input-placeholder {
  color: #b8b8b8;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #b8b8b8;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #b8b8b8;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #b8b8b8;
  opacity: 1;
}

.form-control::placeholder {
  color: #b8b8b8;
  opacity: 1;
}

.form-control {
  height: 3.8rem;
  font-family: "Almarai", sans-serif;
  font-size: 1.07rem;
  color: #757575;
  font-weight: 300;
  border-radius: 0px;
  border: 1px solid #e6e6e6;
  padding: 0.8rem 1.4rem;
}

.btn {
  background-color: #2f7dc9;
  border: 1px solid #2f7dc9;
  border-radius: 0.25rem;
  font-size: 1.21rem;
  color: #fff;
  font-weight: 500;
  transition: 0.4s ease all;
  padding: 0.85rem 2rem;
}

.btn-cancel {
  background-color: #ff4d4d;
  border: 1px solid #ff4d4d;
  border-radius: 0.25rem;
  font-size: 1rem;
  color: #fff;
  font-weight: 500;
  transition: 0.4s ease all;
  padding: 0.6rem 0.8rem;
}

.btn-cancel:hover {
  background: #ffffff;
  color: #ff4d4d;
  border: 1px solid #ff4d4d;
}

.btn-reorder {
  background-color: #2f7dc9;
  border: 1px solid #2f7dc9;
  border-radius: 0.25rem;
  font-size: 1rem;
  color: #fff;
  font-weight: 500;
  transition: 0.4s ease all;
  padding: 0.6rem 0.8rem;
  cursor: pointer;
}

.btn-reorder:hover {
  background: #ffffff;
  color: #2f7dc9;
  border: 1px solid #2f7dc9;
}

.btn-track {
  background-color: #3cb371;
  border: 1px solid #3cb371;
  border-radius: 0.25rem;
  font-size: 1rem;
  color: #fff;
  font-weight: 500;
  transition: 0.4s ease all;
  padding: 0.6rem 0.8rem;
}

.btn-track:hover {
  background: #ffffff;
  color: #3cb371;
  border: 1px solid #3cb371;
}

button:focus {
  border: 0;
  outline: none;
}

.btn:hover {
  background: #ffffff;
  color: #2f7dc9;
  border: 1px solid #2f7dc9;
}

select.form-control {
  font-size: 1.07rem;
  color: #757575;
  font-weight: 300;
  appearance: none;
  background-image: url("/assets/img/custom_select.svg");
  background-size: 0.75rem;
  background-repeat: no-repeat;
  background-position: right 1.07rem center;
  padding-right: 2.9rem;
}

/* Custom Checkbox */
.custom_check {
  display: inline-block;
  position: relative;
  cursor: pointer;
  line-height: 1.6rem;
  padding-left: 2.14rem;
}

.custom_check input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 2;
}

.custom_check .check_indicator {
  height: 1.57rem;
  width: 1.57rem;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 0px;
}

.custom_check input:checked+.check_indicator:before {
  width: 0.5rem;
  height: 0.86rem;
  position: absolute;
  left: 0.43rem;
  top: 2px;
  border: solid #fff;
  border-width: 0px 2px 2px 0px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
}

.custom_check input:checked+.check_indicator {
  background: #348ad2;
  border-color: #348ad2;
}

/* Custom Checkbox */

/* React Notifications */
.notification-container {
  width: 450px !important;
}

/* LOGIN CSS */
.login_signup_sec {
  min-height: 30rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.login_signup_block {
  background-color: #ffffff;
  box-shadow: 0rem 0rem 1.07rem rgba(204, 204, 204, 0.15);
}

.form_logo img {
  max-width: 6rem;
}

.forgot_password_sec a {
  display: inline-block;
  font-size: 1.14rem;
  font-weight: 400;
  color: #d5d5d5;
}

.login_signup_form .btn {
  font-weight: 600;
  padding: 10px 25px;
  font-size: 14px;
}

.or_devider {
  font-size: 1.14rem;
  font-weight: 600;
  color: #808080;
}

.btn.facebook {
  background-color: #3c5b9a;
  font-weight: 500;
  border: 1px solid #3c5b9a;
}

.btn.facebook i {
  font-size: 2rem;
}

.btn.facebook span {
  font-weight: 600;
}

.btn.google {
  background-color: #df4931;
  font-weight: 500;
  border: 1px solid #df4931;
}

.btn.google i {
  font-size: 2rem;
}

.btn.google span {
  font-weight: 600;
}

.dont_signup_text {
  font-size: 1.14rem;
  font-weight: 400;
  color: #808080;
}

.dont_signup_text a {
  color: #2f7dc9;
}

.dont_signup_text a:hover {
  color: #2f7dc9;
}

.trems_use_text {
  font-size: 1.28rem;
  font-weight: 400;
  line-height: 1.6;
}

.trems_use_text a {
  font-weight: 500;
}

.checkoutadd .btn {
  padding: 5px 14px;
}

/* SIDEBAR CSS */

.sidebar {
  width: 18.21rem;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  display: block;
  box-shadow: 3px 0px 7px 0px rgba(0, 0, 0, 0.16);
  z-index: 999;
  padding: 1rem 0rem;
  overflow-x: scroll;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}

.sidebar .logo {
  display: block;
  padding: 2.85rem 0rem;
}

.sidebar .logo img {
  width: 9rem;
  display: block;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  margin: 0 auto;
}

.sidebar_nav li {
  display: block;
  position: relative;
}

.sidebar_nav li a {
  position: relative;
  font-family: "Almarai", sans-serif;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #9c9c9c;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #f3f3f3;
  font-weight: 400;
  padding: 0.9rem 1.5rem;
}

.sidebar_nav li i {
  min-width: 1.8rem;
  text-align: center;
  font-size: 1.5rem;
  margin-right: 0.7rem;
}

.sidebar_nav li:hover a,
.sidebar_nav li.active a {
  background-color: #007ecd;
  color: #ffffff;
}

/* HEADER CSS */
header {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0rem 0rem 1.07rem rgba(0, 0, 0, 0.1);
  z-index: 9;
}

.navbar {
  padding: 0.5rem 0rem;
}

.navbar-nav {
  max-width: 50%;
  align-items: center;
}

.web_logo {
  margin-bottom: -3.5rem;
}

.web_logo img {
  min-width: 15rem;
  width: 15rem;
}

.navbar-nav .nav-item {
  padding: 2px 6px;
}

.navbar-nav .nav-item a {
  font-size: 1rem;
  color: #6b6b6b;
  font-weight: 400;
  padding: 2px 8px;
}

.navbar-nav .nav-item:hover a,
.navbar-nav .nav-item a.active {
  color: #2f7dc9;
}

.navbar-nav .nav-item.login_signup a,
.checkoutadd span .btn,
.top_user_profile a {
  min-width: 5.35rem;
  min-height: 2.35rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  background-color: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #2f7dc9;
  color: #2f7dc9;
}

.checkoutadd span .btn {
  min-width: auto;
}

.navbar-nav .nav-item.login_signup a:hover,
.navbar-nav .nav-item.login_signup a.sign_up,
.top_user_profile a:hover {
  background-color: #2f7dc9;
  color: #ffffff;
}

/* HOME CSS */
.home_banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  overflow: hidden;
  background-color: #fff;
}

.home_banner {
  display: block;
}

.home_banner_img {
  width: 100% !important;
  height: 35rem;
  object-fit: contain;
}

.banner_title {
  font-size: 5.5rem;
  font-weight: 900;
  color: #2f7dc9;
  text-transform: uppercase;
}

.banner_title small {
  display: block;
  font-size: 75%;
  font-weight: 900;
  color: #1c1c1c;
}

.banner_text {
  font-size: 1.43rem;
  color: #737373;
}

.slick-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
  bottom: 0rem;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 1rem 0rem;
}

.slick-dots li {
  width: auto;
  height: auto;
  margin: 0rem 0.3rem;
}

.slick-dots li button {
  width: 1rem;
  height: 1rem;
  background-color: #dcdfe4;
  border-radius: 50%;
  padding: 0rem 0rem;
}

.slick-dots li.slick-active button {
  background-color: #2f7dc9;
}

.slick-dots li button::before {
  display: none;
}

.section_title {
  font-size: 2.42rem;
  font-weight: 600;
  color: #414141;
  position: relative;
}

.section_title::after {
  width: 5.7rem;
  height: 1px;
  background-color: #2f7dc9;
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 0%);
  content: "";
}

.laundries_list>li {
  display: flex;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
  padding: 1.07rem 1.2rem;
  margin-top: 0.8rem;
  position: relative;
}

.laundries_list>li:first-child {
  margin-top: 0rem;
}

.laundries_desc {
  display: flex;
}

.laundries_desc_data {
  padding-left: 1.57rem;
}

.laundries_desc_data_title {
  font-size: 1.57rem;
  font-weight: 600;
  color: #161616;
  text-transform: capitalize;
}

.branch_title {
  font-size: 1.2rem;
  font-weight: 400;
  color: #161616;
  text-transform: capitalize;

  margin-left: 15px;
  margin-right: 15px;
}

.laundries_desc_data_list li {
  width: 50%;
  font-size: 1.07rem;
  color: #979797;
  font-weight: 400;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  text-transform: capitalize;
}

/* .like_rate_view {
  position: absolute;
  right: 0;
  top: 0;
} */
.ratting_view {
  font-size: 1.21rem;
  color: #979797;
}

.ratting_view i {
  font-size: 1.28rem;
  color: #efce4a;
}

.like_view {
  font-size: 2rem;
  color: #fb0404;
  display: flex;
  justify-content: flex-end;
}

.unlike_view {
  font-size: 2rem;
  color: #adb5bd;
}

.laundries_rates .btn {
  font-size: 1.07rem;
  font-weight: 400;
  padding: 0.5rem 1.07rem;
}

.download_app_sec {
  min-height: 62rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.download_title {
  color: #000000;
  font-size: 2.6rem;
  font-weight: 600;
}

.download_content p {
  color: #4f4f4f;
  font-size: 1.3rem;
  font-weight: 500;
}

.Arabic .download_content p {
  font-weight: 600;
  font-size: 1.2rem;
}

.download_text {
  color: #000000;
  font-size: 2rem;
  font-weight: 550;
}

/* PRIVACY POLICY CSS */
.breadcrumb_sec {
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
}

.breadcrumb_sec .breadcrumb {
  margin: 0;
  background-color: #fff;
  padding: 1rem;
}

.breadcrumb_sec .breadcrumb-item {
  font-size: 0.95rem;
  font-weight: 500;
  color: #636363;
}

/*.breadcrumb_sec .breadcrumb-item a{color:#ff914d;}
.breadcrumb_sec .breadcrumb-item:hover a{ color:#ff914d;}*/

.content_block p {
  font-weight: 500;
  line-height: 1.7;
}

.content_block .inner_heading_title {
  font-size: 2.3rem;
  color: #080d2d;
  font-weight: 700;
}

/* ORDER CSS */
.main {
  margin-left: 18.21rem;
}

.after_login_header {
  border-bottom: 1px solid #e7f2f3;
}

.after_login_header .navbar-expand-md {
  box-shadow: none;
}

.top_user_profile {
  max-width: 15rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: center;
}

.user_pic_after_login {
  width: 2.85rem;
  /* height: 2.85rem; */
  border-radius: 50%;
  border: 1px solid #317ec9;
  float: left;
  overflow: hidden;
  margin-right: 8px;
}

.user_pic_after_login img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  display: inline-block;
}

.box_grey {
  background: #fefefe;
  border: 1px solid #efefef;
  border-radius: 0.2rem;
}

.order_tabs {
  /* border: 1px solid #2f7dc9; */
  border-radius: 0.2rem;
  display: inline-flex;
}

.cursor-pointer {
  cursor: pointer;
}

.form-group .repeated_order_delivery_date {
  height: 3.8rem !important;
}

.order_tabs div a {
  min-width: 13rem;
  text-align: center;
  color: #ababab;
  font-size: 1rem;
  font-weight: 400;
  border: none;
  background: #fff;
  border-radius: 0;
  padding: 0.75rem 1.07rem;
}

.text-align-center {
  text-align: center;
}

.easy_pickup {
  border-left: 1px solid #2f7dc9 !important;
  border-right: 1px solid #2f7dc9 !important;
}

.order_tabs div {
  margin: 0;
}

.order_tabs div a.active {
  background: #2f7dc9;
  color: #fff;
}

.filter_search {
  position: relative;
  min-width: 22.85rem;
}

.filter_search .btn_search {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  background: none;
  border: none;
}

.filter_search .btn_search i {
  color: #353535;
}

.filter_search .form-control {
  border: 1px solid #ebebeb;
  color: #353535;
  font-weight: 300;
  font-size: 0.92rem;
  height: 3rem;
  border-radius: 0.5rem;
}

.filter_btn {
  border: 1px solid #efefef;
  height: 3rem;
  border-radius: 0.5rem;
  min-width: 6.42rem;
  color: #353535;
  font-weight: 300;
  font-size: 0.92rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.box_grey.bg-white {
  box-shadow: 5px 0px 7px rgba(0, 0, 0, 0.05);
}

.order_info span,
.order_info p {
  font-size: 1rem;
  color: #979797;
  font-weight: 400;
  line-height: 23px;
}

.btn_orders {
  margin-right: 10px;
  justify-content: flex-end;
}

.btn_orders .btn {
  border: 1px solid #efefef;
  font-size: 0.85rem;
  font-weight: 300;
  background-color: transparent;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0.5rem;
}

.btn_packed {
  border-color: green !important;
  color: green !important;
}

.btn_cancel {
  border-color: red !important;
  color: red !important;
}

/* FAVOURITE CSS */
.inner_laundries_list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.inner_laundries_list li {
  width: calc(50% - 30px);
  display: flex;
  margin: 15px;
}

.inner_laundries_list>li:first-child {
  margin-top: 15px;
}

.laundries_desc_data_list {
  max-width: 100%;
}

/* .laundries_desc_data_list {
  margin-left: -15px;
  margin-right: -15px;
} */
.laundries_desc_data_list li,
.laundries_desc_data_title {
  margin: 0px 15px;
}

.inner_laundries_list li .laundries_desc_img {
  min-width: 7rem;
  max-width: 7rem;
}

.inner_laundries_list li .laundries_desc_img img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}

.inner_laundries_list .laundries_rates {
  min-width: 10rem;
  margin-top: 5px;
}

.like_view {
  position: static;
  font-size: 23px;
  color: #fb0404;
}

.unlike_view {
  position: static;
  font-size: 23px;
  color: #adb5bd;
}

/* MANAGE ACCOUNT CSS */
.page_title {
  font-size: 2rem;
}

.manage_account_user_sec1 {
  /* position: absolute; */
  background-color: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.05);
}

.manage_account_user_sec {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.05);
}

.manage_account_user_info_sec {
  display: flex;
}

.manage_account_user_info {
  padding-left: 2rem;
}

.manage_account_pic {
  width: 12rem;
  height: 12rem;
  position: relative;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.05);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.manage_account_pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.manage_account_name {
  font-size: 1.57rem;
  font-weight: 600;
  color: #2e2e2e;
}

.manage_account_name small {
  display: block;
  font-size: 75%;
  font-weight: 400;
  color: #aeaeae;
}

.manage_account_user_info_list {
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  color: #aeaeae;
  font-weight: 400;
  line-height: 1.5;
}

.manage_account_user_info_list i {
  margin-top: 0.3rem;
  margin-right: 0.75rem;
}

.edit_profile_btn {
  position: absolute;
  top: 1.07rem;
  right: 1.07rem;
  font-size: 0.95rem;
  font-weight: 400;
  padding: 0.4rem 0.5rem;
}

.noorder {
  width: 100%;
  min-height: 250px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2f7dc9;
  font-weight: 600;
}

.settings {
  width: 100%;
  min-height: 30px;
  /* height: 400px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2f7dc9;
  font-weight: 600;
}

.noorder1 {
  width: 100%;
  color: #2f7dc9;
  font-weight: 600;
  max-width: 500px;
  background: #fff;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  box-shadow: 0px 0px 21px -1px rgb(0 0 0 / 10%);
  border-radius: 8px;
}

.noorder1 h3 {
  margin: 12px 0 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.manage_Account_links {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.manage_Account_links li {
  min-width: 20rem;
  padding: 15px;
}

.manage_Account_links li a {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.14rem;
  font-weight: 500;
  color: #2e2e2e;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  padding: 1.2rem 1.2rem;
}

.manage_Account_links li a:hover {
  background-color: rgba(0, 0, 0, 0.03);
  color: #007ecd;
}

.about_me_heading {
  font-size: 1.43rem;
  font-weight: 500;
  color: #2e2e2e;
}

.about_me_text {
  font-size: 1.14rem;
  font-weight: 300;
  color: #adadad;
  line-height: 1.6;
}

/* PROFILE EDIT CSS */
.edit_profile_sec {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 0.3rem;
  right: 0.5rem;
}

.edit_profile_sec input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0%;
  top: 0%;
  text-indent: -9999px;
  cursor: pointer;
  z-index: 9;
}

.form-control {
  font-weight: 400;
}

.input_field_icon_sec {
  position: relative;
}

.input_field_icon {
  width: 3.8rem;
  height: 3.8rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0%;
  right: 0%;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

/* PROFILE VIEW CSS */
.page_title {
  font-size: 2rem;
}

.manage_account_user_sec {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.05);
}

.manage_account_user_info_sec {
  display: flex;
}

.manage_account_user_info {
  padding-left: 2rem;
}

.manage_account_pic {
  width: 12rem;
  height: 12rem;
  position: relative;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.05);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.manage_account_pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.manage_account_name {
  font-size: 1.57rem;
  font-weight: 600;
  color: #2e2e2e;
}

.manage_account_name small {
  display: block;
  font-size: 75%;
  font-weight: 400;
  color: #aeaeae;
}

.manage_account_user_info_list {
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  color: #aeaeae;
  font-weight: 400;
  line-height: 1.5;
}

.manage_account_user_info_list i {
  margin-top: 0.3rem;
  margin-right: 0.75rem;
}

.Arabic .manage_Account_links i {
  margin-right: auto;
  transform: rotate(180deg);
  margin-left: 0px !important;
}

/* .Arabic .react-switch-handle{
  transform: translateX(0px)!important;
} */
.edit_profile_btn {
  position: absolute;
  top: 1.07rem;
  right: 1.07rem;
  font-size: 0.95rem;
  font-weight: 400;
  padding: 0.4rem 0.5rem;
}

.manage_Account_links {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.manage_Account_links li {
  min-width: 20rem;
  padding: 15px;
}

.manage_Account_links li a {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.14rem;
  font-weight: 500;
  color: #2e2e2e;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  padding: 1.2rem 1.2rem;
}

.manage_Account_links li a:hover {
  background-color: rgba(0, 0, 0, 0.03);
  color: #007ecd;
}

.about_me_heading {
  font-size: 1.43rem;
  font-weight: 500;
  color: #2e2e2e;
}

.about_me_text {
  font-size: 1.14rem;
  font-weight: 300;
  color: #adadad;
  line-height: 1.6;
}

/* VENDOR LIST CSS */
.vendor_list_filter_sec {
  display: flex;
  align-items: center;
}

.vendor_list_filter_sec .section_title::after {
  display: none;
}

.sortby_filter_sec {
  display: flex;
  align-items: center;
  padding-left: 1.07rem;
  margin-left: auto;
}

.sortby_filter_sec form .form-control {
  width: 10rem;
  height: 3rem;
  border: 1px solid #e6e6e6;
  padding: 0.7rem;
}

.modal_view_sec {
  max-width: 650px;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  overflow: hidden;
}

.modal_view_sec .modal-content {
  background-color: transparent;
  border: 0rem;
}

.modal_view_sec .modal-body {
  background-color: #ffffff;
  padding: 2rem 2.5rem;
}

.filter_view img {
  width: 2.5rem;
  margin-left: 1.5rem;
  cursor: pointer;
  margin-right: 1.5rem;
}

.filter_modal_header {
  background-color: #2f7dc8;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.85rem 1.07rem;
}

.filter_modal_header a {
  color: #ffffff;
  padding: 0.35rem;
}

.field_view_name {
  width: 100%;
  display: block;
  font-size: 1.28rem;
  font-weight: 500;
}

.btn1 {
  background-color: white;
  border: 1px solid #2f7dc9;
  border-radius: 0.25rem;
  font-size: 1.21rem;
  color: #2f7dc9;
  font-weight: 500;
  transition: 0.4s ease all;
  padding: 0.85rem 2rem;
}

.btn1:hover {
  background: #2f7dc9;
  color: #fff;
  border: 1px solid #fff;
}

.btn2 {
  background-color: white;
  border: 1px solid #2f7dc9;
  border-radius: 0.25rem;
  font-size: 1.21rem;
  color: #2f7dc9;
  font-weight: 500;
  transition: 0.4s ease all;
  padding: 0.5rem 1rem;
}

.btn2:hover {
  background: #2f7dc9;
  color: #fff;
  border: 1px solid #fff;
}

.input-range__label-container {
  font-size: 1rem;
}

.input-range__track--active,
.input-range__slider {
  background: #2f7dc8;
  border-color: #2f7dc8;
}

.daycheck_type_list_sec,
.filterby_radio_list_sec {
  margin: -5px;
}

.daycheck_type_list_sec li,
.filterby_radio_list_sec li {
  display: inline-block;
  position: relative;
  margin: 5px;
}

.daycheck_type_list_sec li input,
.filterby_radio_list_sec input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  opacity: 0;
  cursor: pointer;
  margin: 0rem 0rem;
  z-index: 2;
}

.daycheck_type_list_sec li .daycheck_type_list {
  min-width: 7.5rem;
  background-color: #ffffff;
  border: 1px solid #b1b4bb;
  border-radius: 0.35rem;
  font-size: 1.14rem;
  color: #2e2e2e;
  text-align: center;
  padding: 0.7rem 1.07rem;
}

.daycheck_type_list_sec li input:checked+.daycheck_type_list {
  border-color: #2f7dc8;
  background-color: #2f7dc8;
  color: #ffffff;
}

.daycheck_type_list_sec.rating li .daycheck_type_list {
  min-width: 5rem;
}

.daycheck_type_list_sec.rating li .daycheck_type_list i {
  color: #fcc729;
}

.filterby_radio_list_sec {
  display: flex;
}

.filterby_radio_list_sec li {
  display: inline-flex;
  align-items: center;
  flex: 1;
}

.filterby_radio_list_sec li .filterby_radio {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #747474;
  position: relative;
}

.filterby_radio_list_sec li input:checked+.filterby_radio,
.filterby_radio_list_sec li input:checked+.filterby_radio+span {
  border-color: #2f7dc8;
  color: #2f7dc8;
  font-weight: 400;
}

.filterby_radio_list_sec li .filterby_radio::after {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #2f7dc8;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  content: "";
}

.filterby_radio_list_sec li input:checked+.filterby_radio::after {
  opacity: 1;
}

/* VENDOR DETAIL CSS */
.vendor_list_pic_sec {
  position: relative;
  width: 100%;
  height: 28rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fdfdfd;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.vendor_list_pic_sec img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}

.vendor_list_detail_heading {
  font-size: 1.43rem;
  font-weight: 600;
  color: #2e2e2e;
  text-align: center;
  width: 40%;
}

span.reating {
  background: #2f7dc9;
  color: #fff;
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 5px;
  float: right;
}

span.rating {
  background: #2d7d30;
  color: #fff;
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 5px;
}

span.distance {
  color: #979797;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
  display: block;
  margin-top: 5px;
  /* float: right; */
}

.address p {
  width: 100%;
}

.address p,
span.distance {
  font-weight: 400;
  color: #979797;
}

span.price {
  font-size: 18px;
  position: relative;
  top: 3px;
  color: #2f7dc9;
  font-weight: 600;
}

.paymentlist p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  color: #979797;
  clear: both;
}

.paymentlist p span {
  color: #2f7dc9;
  /* font-weight: 600; */
}

.tags button.btn {
  font-size: 0.9rem;
  padding: 6px 15px;
  margin: 0px 10px 10px 0px;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  color: #818181;
}

.tags h4 {
  margin-bottom: 15px;
  font-size: 18px;
}

.like_view1 {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 23px;
  color: #fb0404;
}

.unlike_view1 {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 23px;
  color: #adb5bd;
}

/* FOOTER CSS */
footer {
  min-height: 7rem;
  background-color: #4d4d4d;
  background-repeat: repeat-x;
  background-position: center bottom -2.5rem;
  background-size: 20rem;
}

.footer_links,
.footer_link,
.social_link {
  display: flex;
}

.footer_links {
  align-items: center;
}

.social_link {
  margin-left: auto;
}

.footer_link li {
  font-size: 0.9rem;
  font-weight: 400;
  color: #ffffff;
  padding: 0rem 0.85rem;
}

.footer_link li:first-child,
.footer_link li:first-child a {
  padding-left: 0rem;
}

.laundries_rates {
  text-align: center;
  min-width: 120px;
}

.footer_link li a {
  color: #ffffff;
  padding: 0rem 0.5rem;
}

.footer_link li:hover a {
  color: #ff914d;
}

.social_link li {
  padding: 0rem 0.35rem;
}

.social_link li:last-child {
  padding-right: 0rem;
}

.social_link li a {
  width: 2.43rem;
  height: 2.43rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* background-color: #348ad2; */
  color: #ffffff;
}

.social_link a i {
  font-size: 1.3rem;
}

.social_link li.facebook a {
  background-color: #0e8cf1;
}

.social_link li.twitter a {
  background-color: #50abf1;
}

.social_link li.linkedin a {
  background-color: #0084b1;
}

.social_link li.snapchat a {
  background-color: #fffc00;
  color: white;
}

.social_link li.youtube a {
  background-color: #ff0000;
}

.social_link li.instagram a {
  background-color: #e1306c;
}

.Arabic .arabic_change {
  margin-right: auto;
  margin-left: 0 !important;
}

.social_link li:hover a {
  background-color: #ff914d;
  color: #ffffff;
}

/* BOOK SERVICE CSS */
.catgoryproductouter {
  background: #fff;
  box-shadow: 0px 0px 20px #e8e8e8cc;
  border-radius: 10px;
  overflow: hidden;
  padding: 15px 15px 10px 15px;
  margin-bottom: 30px;
}

.productimgleft {
  width: 70px;
}

.productdetails h4 {
  color: #000;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.productdetails h4 a {
  color: #000;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.productdetails {
  width: calc(100% - 90px);
  padding-left: 15px;
}

.productdetails p {
  color: #555555;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
}

.productimgleft img {
  border-radius: 10px;
  background: #f5f5f5;
  padding: 1px;
}

.dryclean {
  background: #f2f2f7;
  padding: 10px;
  border-radius: 5px;
}

.dryclean h5 {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
}

.dryclean p {
  font-size: 12px;
  color: #555555;
  font-weight: 400;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.dryclean p span {
  color: #2f7dc9;
}

.Arabic .vender-img {
  margin-left: 14px;
}

.Arabic .accordion__button:after {
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(130deg);
  left: 0px;
}

span.pr-3.d-inlne {
  display: flex !important;
  justify-content: space-between;
}

.Arabic .accordion__button[aria-expanded="true"]::after,
.accordion__button[aria-selected="true"]::after {
  transform: rotate(45deg);
}

.Arabic .accordion__button:before {
  display: none;
}

.Arabic .arabic_innerheading {
  margin-right: auto;
}

.Arabic .arabic_innerheading .Innerheading1 label {
  padding: 0px;
}

.Arabic .custom_radio .radio_indicator {
  right: 0;
}

.credit_card_arabic_amount {
  padding: 5px 0px !important;
}

.Arabic .custom_radio {
  padding-left: 0px;
}

.Arabic .custom_radio p {
  text-align: right;
  margin-right: 33px !important;
}

.cash_omr_amount {
  display: flex;
}

.Arabic .cash_omr_amount {
  flex-direction: row-reverse;
  margin-left: 17px;
}

.Arabic .credit_card_arabic_amount {
  flex-direction: row-reverse;
  margin: 0px 16px;
}

.Arabic .laundries_list .accordion__item {
  text-align: right;
}

.Arabic .accordion__button {
  text-align: right;
}

.arabic_innerheading .Innerheading1 label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 67px 0px 7px;
}

.Arabic .dryclean p span {
  float: left;
}

.Arabic .priceaddon1 {
  float: left !important;
}

.catgoryproductouter ul {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.catgoryproductouter ul li {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
}

.catgoryproductouter .slick-slider {
  overflow: hidden;
  width: 100%;
}

.catgoryproductouter .slick-slide>div {
  padding: 0px 5px;
}

.checkoutadd {
  background: #fff;
  box-shadow: 0px 0px 20px #eaeaea;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.checkoutadd a.btn {
  background: transparent;
  color: #2f7dc9;
  padding: 5px 20px;
}

.checkoutadd a.btn:hover {
  background: #2f7dc9;
  color: #fff;
}

.checkoutadd h3 {
  font-size: 18px;
  font-weight: 600;
}

.checkoutadd p {
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 15px;
}

/* .checkoutadd p span {
  color: #2f7dc9;
} */
.login_signup_sec form select.form-control {
  font-weight: 400;
  color: #757575;
}

.laundries_desc_img {
  width: 165px;
  min-width: 165px;
}

.checkoutadd a.btn.editbtn {
  padding-left: 10px;
  padding-right: 10px;
}

.checkoutadd a.btn.editbtn span {
  margin-left: 12px;
}

.checkoutadd a.btn.editbtn:hover .text-danger {
  color: #fff !important;
}

.productdetails i {
  /* position: absolute;
  right: 5px;
  top: 0px; */
  color: red;
  font-size: 19px;
  cursor: pointer;
}

.productdetails {
  position: relative;
}

/* Bootstrap css */
.btn-secondary:hover {
  border-color: #2f7dc9;
}

/* Bootstrap css */

.switch {
  width: 50px;
  height: 30px;
  display: inline-block;
  border-radius: 35px;
  overflow: hidden;
  position: relative;
  margin: 5px 0px;
}

.switch input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  padding: 0px 0px;
  margin: 0px 0px;
}

.Arabic .sidebar_nav li i {
  margin-left: 8px;
}

.Arabic .user_pic_after_login {
  margin-left: 7px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4d4d4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 4px;
  bottom: 4px;
  background-color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-indent: 30px;
  font-size: 14px;
  color: #2196f3;
}

.switch input:checked+.slider {
  background-color: #2196f3;
}

.switch input:checked+.slider:before {
  left: calc(100% - 26px);
  content: "";
  color: #ffffff;
  text-indent: -30px;
}

.headingouter h2 {
  font-size: 2.42rem;
  font-weight: 600;
  color: #414141;
  position: relative;
}

.Innerheading {
  margin-top: 7px;
  /* background: #f3f3f3; */
  padding: 5px 15px;
  border-radius: 5px;
  border: 2px solid #2f7dc978;
}

.Innerheading1 {
  margin-top: 7px;
  /* background: #f3f3f3; */
  padding: 5px 15px;
  border-radius: 20px;
  border: 4px solid#d64444;
}

.leftpartcollection {
  width: 50%;
  float: left;
  border-right: 1px solid #2f7dc9;
  padding: 15px;
}

.collectiondate {
  border: 1px solid #4088cd;
  display: inline-block;
  width: 100%;
  border-radius: 5px;
}

.leftpartcollection p,
.leftpartcollection h4 {
  color: #000;
}

.leftpartcollection i {
  font-size: 22px;
  color: #307ec9;
}

.pickupAddress {
  padding: 15px;
  border: 1px solid#2f7dc9;
  border-radius: 5px;
  margin-bottom: 10px;
}

.pickupAddress h4 {
  font-size: 18px;
  margin-bottom: 8px;
}

.pickupAddress p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0px;
}

.pickupAddress i {
  color: #307ec9;
}

.slick-slider .slick-track {
  margin-left: 0px;
}

.ourorderdetails {
  display: flex;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-top: 15px;
  padding: 10px;
}

.ourorderdetails:hover {
  box-shadow: 0px 0px 11px #f3f3f3;
  cursor: pointer;
}

.orderedproductimg {
  width: 65px;
}

.orderedproductcontent {
  width: calc(100% - 65px);
  padding-left: 15px;
}

.orderedproductcontent p {
  margin-bottom: 5px;
}

.orderedproductcontent p {
  font-weight: 500;
  font-size: 14px;
}

.copuncode {
  border: 1px solid #2f7dc9;
  margin-bottom: 10px;
  padding: 10px 13px 13px;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  font-weight: 100;
  width: 100%;
}

.priceBreakup {
  /* width: calc(100% / 2 - 15px); */
  margin: 15px 0px;
  border: 1px solid #2f7dc9;
  border-radius: 5px;
  padding: 15px;
}

.priceBreakup p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
}

.priceBreakup p strong {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #2f7dc9;
}

.custom_check {
  display: inline-block;
  position: relative;
  cursor: pointer;
  line-height: 22px;
  position: relative;
  padding-left: 30px;
  margin: 5px 0px;
}

.custom_check input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 2;
}

.custom_check .check_indicator {
  height: 22px;
  width: 22px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 0px;
}

.custom_check input:checked+.check_indicator:before {
  width: 7px;
  height: 12px;
  position: absolute;
  left: 6px;
  top: 2px;
  border: solid #fff;
  border-width: 0px 2px 2px 0px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
}

.availableouter {
  border: 1px solid #eaeaea;
  margin-bottom: 20px;
}

/* .availableouter h5 {
  
} */
.tab_inner_div {
  background: #2f7dc9;
  padding: 11px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.availableouter ul li {
  padding: 10px;
  font-weight: 500;
  border-bottom: 1px solid #eaeaea;
}

.availableouter ul li:last-child {
  border: 0px;
}

.vendor_list_detail_sec .section_title::after {
  display: none;
}

.vendor_list_detail_sec {
  min-height: calc(100vh - 190px);
}

.clearCart .btn {
  padding: 15px 10px;
  font-size: 13px;
  background: transparent;
  border-color: #2f7dc9;
  color: #2f7dc9;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clearCart .btn:focus,
.clearCart .btn:active,
.clearCart .btn:hover {
  border: 1px solid #2f7dc9;
  background: #2f7dc9;
  color: #fff;
}

.react-datepicker-wrapper {
  width: 100%;
}

.navbar-nav .nav-item.login_signup a.cartOuterbtn,
.top_user_profile a {
  min-width: auto;
}

.top_user_profile a {
  padding: 0px 10px;
  margin-right: 10px !important;
}

a.cartOuterbtn {
  position: relative;
}

a.cartOuterbtn span {
  position: absolute;
  background: #2f7dc9;
  width: 20px;
  height: 20px;
  border-radius: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -9px;
  right: -3px;
  font-size: 10px;
  color: #fff;
}

.dryclean {
  position: relative;
}

.dryclean .fa-pencil-alt {
  /* position: absolute;
  right: 10px;
  top: 10px; */
  color: #2f7dc9;
}

a.toggle_btn:hover {
  color: #2f7dc9 !important;
}

.sidebar_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0;
  transition: 0.4s ease all;
  visibility: hidden;
}

.show_sidebar .sidebar_overlay {
  opacity: 0.7;
  visibility: visible;
}

.toggle_btn {
  position: relative;
  top: -18px;
}

.laundries_desc_data_list li {
  width: 100%;
}

/* font-family: 'Montserrat', sans-serif; */

.panel-group {
  margin-top: 30px;
}

.panel-collapse {
  max-height: 500px;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.panel-close {
  max-height: 0;
}

.custom_radio {
  display: inline-block;
  position: relative;
  cursor: pointer;
  line-height: 22px;
  position: relative;
  padding-left: 30px;
  margin: 5px 0px;
}

.custom_radio input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 2;
}

.custom_radio .radio_indicator {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #cccccc;
  position: absolute;
  top: 0px;
  left: 0px;
}

.custom_radio input:checked+.radio_indicator:before {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #2f7dc9;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -6px 0px 0px -6px;
  content: "";
}

span.addonouter {
  padding: 5px 10px;
  display: block;
  border-bottom: 1px solid #eaeaea;
}

span.addonouter:last-child {
  border: 0px;
}

/******My order details page css start****/
.productitemlist .table {
  border: 1px solid #f3f3f3;
  background: #fdfdfd;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.05);
}

.productitemlist .table th,
.productitemlist .table td {
  border: 1px solid #f3f3f3;
}

.itemimgleft {
  width: 60px;
}

.itemcontents {
  padding-left: 15px;
}

.itemcontents h4 {
  font-size: 16px;
}

.itemcontents p {
  margin: 0px;
  font-size: 14px;
}

.productitemlist .table th {
  font-size: 15px;
  font-weight: 600;
}

.productitemlist .table td {
  font-size: 14px;
  vertical-align: middle;
  font-weight: 400;
}

.orderhead {
  background: #fdfdfd;
  padding: 15px;
  border: 1px solid #f3f3f3;
  border-bottom: 0px;
  font-weight: 500;
  color: #2f7dc9;
}

.pricebekup {
  background: #fdfdfd;
  border: 1px solid #f3f3f3;
}

.pricebekup h4 {
  font-size: 18px;
  padding: 10px 15px;
  border-bottom: 1px solid #f3f3f3;
}

.orderprice {
  padding: 0px 0px 10px;
  font-weight: 400;
  font-size: 14px;
}

.orderprice p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px 0px;
  margin: 10px 0px 0px;
}

.orderprice p.totalp {
  border-top: 1px solid #f3f3f3;
}

.addressbox {
  padding: 15px;
}

.addressbox h5 {
  font-size: 16px;
  margin-bottom: 6px;
}

.addressbox p {
  font-size: 13px;
  font-weight: 400;
}

.addressbox .btn {
  font-size: 15px;
  padding: 5px 15px;
}

.pricebekup .productdetails h4 {
  padding: 0px 0px 10px;
  border: 0px;
  text-transform: capitalize;
}

.pricebekup .d-flex {
  padding: 10px 15px;
}

.selectpoints {
  background: #fdfdfd;
  padding: 15px 22px;
  border: 1px solid #f3f3f3;
}

.selectpoints h6 {
  font-size: 14px;
  text-wrap: wrap;
  color: #504343;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}

.selectpoints h6 p {
  display: inline-block;
  /* margin-left: auto; */
  width: 50.33%;
  margin: 0;
}

.margin {
  margin-bottom: 4%;
  margin-top: 2%;
  line-height: 1.8;
  font-weight: 600;
}

.Arabic .selectpoints h6 .order_inf_layout {
  text-align: right;
}

.selectpoints h6 .order_inf_layout {
  text-align: left;
}

.selectpoints h6 span {
  font-size: 12px;
  color: #2f7dc9;
  /* max-width: 33.33%; */
  word-break: break-all;
  text-align: right;
  width: 33.33%;
}

.vendor_list_detail_view li,
.lable_tag_vendor li {
  display: inline-block;
  background: #ece8e8;
  margin-right: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 500;
  color: #979797;
  font-size: 14px;
  margin-bottom: 10px;
}

.availableouter {
  position: relative;
}

/* .availableouter i {
  position: absolute;
  top: 16px;
  right: 12px;
  color: #fff;
  cursor: pointer;
} */
.workingtime li {
  width: 100%;
  padding: 0px;
  background: transparent;
  color: #9a9a9a;
  display: flex;
}

span.dayname {
  width: 100px;
  min-width: 100px;
}

.themeColor {
  color: #2f7dc9;
}

.loading {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid var(--primary-color);
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: auto auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.vender-img {
  width: 80px;
}

.venderdetailsright {
  width: calc(100% - 80px);
  padding-left: 15px;
}

.venderdetailsright p {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  display: flex;
}

.venderdetailsright p span {
  min-width: 50%;
  display: inline-block;
  line-height: 1.6;
}

.venderdetailsright p a {
  margin-left: 7px;
  font-size: 19px;
  position: relative;
  top: -3px;
}

.manage_account_user_info_sec li.py-2 strong {
  width: 200px;
  display: inline-block;
}

.noorder1 img {
  max-width: 280px;
}

/* .inner_laundries_list li .like_rate_view {
  position: absolute;
  width: auto;
  right: 6px;
  top: 6px;
} */

.home_laundries_list .like_rate_view {
  position: static;
}

.map_grp {
  min-height: 200px;
  margin-bottom: 20px;
}

.login_signup_form label {
  font-weight: 500;
  font-size: 14px;
}

.login_signup_form .form-control {
  padding: 10px 15px;
  height: auto;
  font-size: 13px;
  border-radius: 4px;
}

.selected_adons h3 {
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 15px;
  background: #ffffff;
  padding: 10px;
  border: 1px solid #e7e7e7;
}

.selected_adonsouter h6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected_adonsouter .selected_adons {
  background: #fff;
  padding: 11px 15px;
  border: 1px solid #e6e6e6;
}

.selectedaddonss {
  background-color: red;
}

.selectedaddonssinner {
  background-color: red;
}

.addonsorders {
  justify-content: center;
  align-items: center;
  display: flex;
}

.selected_adonsouter .selected_adons h3 {
  border: 0px;
  padding: 0px;
  margin: 0px;
}

.wrapper {
  min-height: calc(100vh - 173px);
}

/* arabic */
/* 
.Arabic .navbar ul {
  width: 100%;
  justify-content: initial!important;
  display: block!important;
} */
.Arabic .sidebar {
  right: 0;
}

.Arabic .footer_link li {
  font-size: 10px;
}

.Arabic .toggleMe .react-switch-handle {
  transform: translateX(0px) !important;
}

.Arabic .react-switch .react-switch-handle {
  transform: translateX(28px) !important;
}

.Arabic .main {
  margin-right: 18.21rem;
  margin-left: 0rem;
}

.Arabic .navbar-nav .nav-item a {
  padding: 2px 6px;
}



/* corporate customer package details  */
.coporate-customer-key-details {
  font-size: 16px;
}


/* customer corporate package list page */
.customer-corporate-package-list-main .iMnhxj ul {
  margin: 0;
  padding-top: 10px;
  list-style-type: none;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  gap: 31px;
  padding-left: 10px;
}

.packages-service-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: right;
  position: absolute;
  right: 0;
}

.Arabic .packages-service-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: right;
  position: absolute;
  left: 0;
}

.Corporte-Package-Service-List {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
  font-weight: 600;
}

.customer-corporate-package-list-main .iMnhxj {
  padding: 0 !important;
}

.customer-corporate-package-list-main .customer-page.list-sec {
  background-color: #fff;
  box-shadow: unset;
  /* margin-right: 40px; */
  margin-right: 0px;
}

.customer-corporate-package-list-main .promo-list-section-title {
  margin-top: 2% !important;
}

.coporate-customer-key-details-main {
  padding: 5px;
  max-width: 542px;
  margin-right: 30px;
  width: 45%;
}

.coporate-customer-key-details-main1 {
  padding-right: 25px;
  padding-left: 20px;
}

.coporate-customer-key-details-activate-button {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 50px;
  padding-left: 20px;
}

/* arabic */

/* Media CSS */
@media (max-width: 1365.98px) {
  .footer_link li {
    font-size: 8px;
  }

  .Arabic .footer_link li {
    font-size: 8px;
  }
}

@media (max-width: 1199.98px) {
  html {
    font-size: 12px;
  }

  .Arabic .navbar-nav .nav-item a {
    padding: 2px 3px;
  }

  .footer_link li a {
    padding: 0rem 0rem;
  }

  .navbar-nav .nav-item a {
    padding: 2px 8px;
  }

  .pickupAddress p {
    font-size: 14px;
  }

  .inner_laundries_list .laundries_rates {
    width: 100%;
    padding-left: 104px;
    text-align: left;
  }

  .inner_laundries_list li {
    display: block;
  }
}

@media (max-width: 991.98px) {
  .manage_Account_links li {
    min-width: 33.33%;
  }

  .Arabic .navbar-nav .nav-item a {
    padding: 6px 3px;
  }

  .navbar-nav {
    max-width: 63%;
  }

  .Arabic .footer_link li {
    margin-right: 6px;
    font-size: 8px;
  }

  .Arabic .main {
    margin-right: 0px !important;
  }

  .Arabic.show_sidebar nav.sidebar {
    right: 0 !important;
  }

  .Arabic .toggle_btn_outer {
    text-align: right;
  }

  .Arabic nav.sidebar {
    right: -220px !important;
    left: 0px !important;
  }

  .price_break_common {
    text-align: left !important;
  }

  .footer_links {
    flex-direction: column;
  }

  .social_link {
    margin: initial;
    margin-top: 11px;
  }

  .footer_link li {
    padding: 0rem 0.4rem;
  }

  .navbar-nav .nav-item a {
    padding: 2px 2px;
    font-size: 0.83rem;
  }

  .show_sidebar nav.sidebar {
    /* left: 0; */
    z-index: 9;
    visibility: visible;
    opacity: 1;
  }

  nav.sidebar {
    /* left: -220px; */
    transition: 0.4s ease all;
    visibility: hidden;
    opacity: 0;
  }

  .main {
    margin: 0px;
  }

  header .container {
    max-width: 100%;
  }

  .priceBreakup {
    width: 100%;
  }

  .web_logo img {
    min-width: 8rem;
    width: 8rem;
  }

  /* .navbar-nav .nav-item a {
    padding: 0.5rem 0.5rem;
  } */
  .web_logo {
    margin-bottom: -2rem;
  }

  .banner_title {
    font-size: 4rem;
  }

  .laundries_desc_data_list {
    max-width: 100%;
  }

  .btn_app a img {
    max-height: 3.5rem;
  }

  .download_app_sec {
    min-height: 55rem;
  }

  .catgoryproductouter ul li {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .laundries_desc_img {
    width: 100px;
    min-width: 100px;
  }
}

@media (max-width: 767.98px) {

  /* .home_laundries_list .like_rate_view {
    position: absolute;
    top: 5px;
    right: 5px;
    margin: 0;
    padding: 0;
  } */
  .login_signup_form label {
    font-size: 12px;
  }

  .laundries_desc_data_list li,
  .laundries_desc_data_title {
    margin: 10px;
  }

  .select_lang {
    margin-top: 7px;
    border: none;
    width: 19%;
  }

  .list_notification .notification_content .icon_tag span .fas {
    width: 34px;
    height: 34px;
    min-height: 38px;
  }

  .list_notification .notification_content .timing_sec span {
    font-size: 12px;
  }

  .list_notification .notification_content .delete_icon span {
    font-size: 12px;
  }

  .list_notification ul li {
    padding: 12px 13px !important;
  }

  .list_notification .notification_content .notification_item h3 {
    font-size: 16px !important;
  }

  .list_notification .notification_content .notification_item p {
    font-size: 13px;
  }

  .my_cart_content_mb {
    flex-direction: column;
  }

  .home_laundries_list .laundries_rates .btn {
    margin-top: 36px;
  }

  .inner_laundries_list li {
    width: 100%;
  }

  .inner_laundries_list .laundries_rates {
    position: initial;
  }

  .filter_search {
    min-width: calc(100% - 46px);
  }

  .manage_account_pic {
    height: 80px;
    width: 80px;
  }

  .manage_account_user_info {
    width: calc(100% - 80px);
  }

  .manage_Account_links li:last-child {
    width: 100%;
  }

  .manage_Account_links li {
    width: 50%;
    display: inline-block;
    padding: 15px;
  }

  .manage_Account_links {
    display: block;
  }

  .vendor_list_detail_sec {
    padding-top: 30px !important;
  }

  .page_title {
    font-size: 1.5rem;
  }

  .toggle_btn {
    top: -1px;
  }

  /* .laundries_rates {
    position: absolute;
    top: 0px;
    right: 7px;
    text-align: right;
  } */

  .laundries_desc_img {
    float: left;
  }

  .laundries_desc_data {
    width: calc(100% - 105px);
    text-align: left;
    float: left;
  }

  .laundries_desc_data_list {
    margin: 0px;
  }

  .laundries_desc_data_list li {
    margin: 0px;
    width: 100%;
  }

  .home_banner_img {
    height: 25rem;
  }

  .navbar-nav {
    max-width: 100%;
    width: 100%;
  }

  .navbar-collapse {
    padding-top: 0.5rem;
  }

  .navbar-nav .nav-item {
    padding: 0rem 0rem;
    width: 100%;
  }

  .navbar-nav .nav-item.login_signup {
    padding: 0.65rem 0rem 0.2rem;
  }

  .navbar-nav .nav-item a {
    display: block;
    background-color: #efefef;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 0.6rem 1.07rem;
  }

  .navbar-nav .nav-item a.active {
    background-color: #2f7dc9;
    color: #ffffff;
  }

  .navbar-light .navbar-toggler:focus {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .custom-header-user .nav-item:first-child a {
    border-top: 0px;
  }

  .logo img {
    max-width: 5rem;
  }

  a.logo.d-inline-block.d-md-none {
    min-width: 20px;
    width: 60px;
    min-height: 40px;
  }

  .banner_title {
    font-size: 3.5rem;
  }

  .banner_text {
    font-size: 1.28rem;
  }

  .home_banner_slider_sec .slick-slide img {
    max-width: 30rem;
  }

  .slider_data {
    text-align: center;
  }

  /* .laundries_list > li {
    display: block;
  } */
  .like_rate_view {
    justify-content: flex-start;
  }

  .download_app_sec {
    min-height: inherit;
  }

  .download_image {
    text-align: center;
  }

  .download_image img {
    max-width: 25rem;
  }

  .section_title {
    font-size: 1.5rem;
  }

  .footer_links {
    display: block;
  }

  .footer_link {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer_link li {
    padding: 0.5rem 0.8rem;
  }

  .social_link {
    justify-content: center;
    padding-top: 1.07rem;
  }

  .vendor_list_pic_sec {
    height: initial;
  }

  .clearCart {
    position: relative;
    top: -10px;
  }

  ul.order_tabs.nav {
    width: 100%;
  }

  ul.order_tabs.nav li.nav-item {
    width: 50%;
  }

  .order_tabs .nav-item a {
    width: 100%;
    min-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .productitemlist table.table {
    width: 630px;
  }
}

.priceaddon .form-control {
  padding: 4px;
  height: inherit;
  text-align: center;
}

.priceaddon1 {
  padding: 4px;
  height: inherit;
  text-align: center;
  font-weight: 500;
  line-height: 19px;
  font-family: "Almarai", sans-serif;
  font-size: 1.07rem;
}

@media (max-width: 575.98px) {
  .clearCart h2 {
    margin: 0px;
  }

  .copuncode .d-flex {
    flex-direction: column;
  }

  .card_payment .custom-control-label {
    font-size: 13px !important;
  }

  .copuncode .card_payment {
    margin-top: 9px;
  }

  .select_card h2 {
    font-size: 14px !important;
  }

  .select_lang {
    width: 33%;
  }

  .filterby_radio_list_sec {
    display: block;
  }

  .vendor_list_detail_view .address p {
    width: 100%;
  }

  .laundries_desc_img {
    width: 50px;
    min-width: 50px;
  }

  .laundries_desc_data {
    width: calc(100% - 50px);
  }

  .home_banner_img {
    height: 15rem;
  }

  .laundries_desc_data {
    padding-left: 15px;
    padding-top: 0rem;
  }

  .laundries_list>li,
  .laundries_desc_data_list li {
    text-align: left;
  }

  .laundries_desc_data_list li {
    width: 100%;
  }

  .like_rate_view {
    justify-content: center;
  }

  .download_title {
    font-size: 2rem;
  }

  .footer_link li {
    padding: 0.5rem 0.5rem;
  }

  .filter_view img {
    margin-left: 0.5rem;
  }

  .manage_Account_links li {
    width: 100%;
    padding: 5px 15px;
  }
}

@media (max-width: 479.98px) {
  .laundries_desc_data {
    width: calc(100% - 65px);
    margin-left: 15px;
  }

  .order_tabs div a {
    min-width: 9rem;
  }

  .home_laundries_list .laundries_rates .btn {
    margin: 0px;
  }

  /* .laundries_desc {
    display: block;
  } */

  .inner_laundries_list .laundries_rates {
    margin: 0;
    padding: 0;
    clear: both;
  }

  .inner_laundries_list li .laundries_desc_img {
    min-width: 7rem;
    max-width: 7rem;
    margin: 0 auto 15px;
    float: none;
    display: block;
  }

  .laundries_desc_data {
    padding-left: 0;
    margin-bottom: 10px;
  }

  .laundries_rates {
    position: initial;
    text-align: left;
    /* padding-left: 65px; */
  }

  .banner_title {
    font-size: 2.5rem;
  }

  .download_image img,
  .home_banner_slider_sec .slick-slide img {
    max-width: 100%;
  }
}

/* 1/3/2021 */

.list_notification .notification_content {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.list_notification .notification_content .notification_item {
  width: 40%;
}

.list_notification .notification_content .delete_icon {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.list_notification .notification_content .icon_tag span .fas {
  width: 38px;
  height: 38px;
  border: 1px solid #307dc9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  min-height: 38px;
  color: #307dc9;
}

.list_notification .notification_content .notification_item h3 {
  font-size: 17px;
}

.btn_cancel_close .fas {
  font-size: 20px;
  cursor: pointer;
}

.list_notification .notification_content .timing_sec span {
  color: #307dc9;
  font-weight: 500;
}

.list_notification .notification_content .delete_icon span {
  color: #307dc9;
  font-weight: 500;
}

.list_notification ul li {
  border: 1px solid #00000012;
  box-shadow: 3px 0px 7px 0px rgb(0 0 0 / 16%);
  padding: 19px 20px;
  border-radius: 19px;
  background-color: #ffffff;
}

/* .accordion__button:active {
  background-color: #2f7dc9!important;
  color: #fff;
} */
.accordion__button {
  background-color: #fff !important;
  font-weight: 500;
  font-size: 16px;
}

.accordion__button:focus {
  outline: none;
  box-shadow: none;
}

.laundries_quest p {
  font-weight: 500;
  color: #020101a6;
  font-size: 14px;
  line-height: 19px;
}

.selected_adonsouter {
  background-color: #fff;
  padding: 13px 0px;
  box-shadow: 1px 0px 2px 1px #00000029;
  border: 1px solid #2f7dc978;
}

.selected_orders {
  background-color: #fff;
  padding: 13px 0px;
  box-shadow: 1px 0px 2px 1px #00000029;
  border: 1px solid #2f7dc978;
}

.promoColor {
  color: #2f7dc9;
  font-weight: 700;
}

.manage_Account_links li {
  min-width: 17rem;
  padding: 15px;
}

/* 1/3/2021 */

/* coupon_section */

.coupon_sec {
  padding: 21px 25px;
}

.coupon_sec .d-flex {
  box-shadow: 0px 1px 3px 0px rgb(27 23 26 / 20%);
  padding: 13px 12px;
  border-radius: 16px;
}

.coupon_sec .d-flex .coupon_code h3 {
  color: #2f7dc8;
  font-size: 16px;
  margin-bottom: 8px;
}

.coupon_sec .d-flex .coupon_code span {
  color: #2f7dc8;
}

.coupon_sec .d-flex .apply_coupon a {
  display: block;
  background-color: #2f7dc8;
  color: #fff;
  padding: 7px 14px;
  border-radius: 9px;
  font-size: 12px;
  margin-bottom: 20px;
}

.coupon_sec .d-flex .apply_coupon span {
  color: #2f7dc8;
  font-weight: 500;
}

.coupon_sec .d-flex .coupon_code p {
  margin: 0;
  line-height: 24px;
  font-size: 14px;
  color: #0000009e;
  font-weight: 500;
}

.daycheck_type_list_sec .radio-custom input[type="radio"] {
  width: 30px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.radio-custom input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ffa500;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

/* coupon_section */

/* map_marker */

.login_signup_form .map_marker {
  position: absolute;
  right: 10px;
  top: 26%;
  z-index: 2;
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.login_signup_form .map_marker .fa {
  font-size: 19px;
}

/* map_marker */

.select_lang {
  background-color: #efefef;
  border: 1px solid #2f7dc97d;
  padding: 5px 3px;
  border-radius: 5px;
}

.modal-header .close {
  margin: 0px;
  padding: 0px;
}

@media (max-width: 320.98px) {
  .laundries_rates {
    min-width: initial !important;
  }
}

/* 28/4/21 */

.Arabic .vendor_list_detail_view h2 {
  text-align: right;
}

.Arabic .lable_tag_vendor {
  text-align: right;
}

.Arabic .lable_working_vendor {
  text-align: right;
}

.Arabic,
.span.dayname {
  text-align: right;
}

.Arabic .slick-track {
  margin-right: 0 !important;
}

.leftpaneladdres {
  padding: 0;
}

/* 28/4/21 */

/* 12/5/21 */

.card_payment .custom-radio {
  margin-right: 2rem;
}

.card_payment .custom-control-label {
  font-size: 17px;
}

.select_card h2 {
  font-size: 16px;
  margin-left: auto;
  /* text-align: right; */
  margin-top: 10px;
  color: #675353;
}

.select_card {
  cursor: auto;
  margin: 10px 0;
  padding: 0 10px;
  background: #e6e6e6;
  border-radius: 6px;
}

.select_card .justify-content-between {
  align-items: center;
}

.select_card .card_number {
  font-size: 13px;
}

.select_card span {
  color: #2f7dc9;
  font-weight: 600;
  margin-left: 5px;
  margin-right: 5px;
}

.Arabic .copuncode i {
  margin-right: auto;
  /* transform: rotate(180deg); */
  margin-left: 0px !important;
}

.Arabic .copuncode .chevron {
  margin-right: auto;
  transform: rotate(180deg);
  margin-left: 0px !important;
}

/* 12/5/21 */

.modalLayout {
  width: 500px;
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

/* .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-size-small {
  font-size: 0.75em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}
.ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}
blockquote {
  border-left: 4px solid #ccc;
  padding-left: 16px;
}

code,
pre {
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 6px 10px;
}

ul > li[data-checked="true"]::before {
  content: "\2611";
}
ul > li[data-checked="false"]::before {
  content: "\2610";
}

ol > li,
ul > li {
  list-style-type: none;
}

ol {
  counter-reset: mylist;
}

ol > li:before {
  counter-increment: mylist;
  content: counter(mylist, decimal) ". ";
}
ol ol > li:before {
  content: counter(mylist, lower-alpha) ". ";
}
ol ol ol > li:before {
  content: counter(mylist, lower-roman) ". ";
}
ol ol ol ol > li:before {
  content: counter(mylist, decimal) ". ";
}
ol ol ol ol ol > li:before {
  content: counter(mylist, lower-alpha) ". ";
}
ol ol ol ol ol ol > li:before {
  content: counter(mylist, lower-roman) ". ";
}
ol ol ol ol ol ol ol > li:before {
  content: counter(mylist, decimal) ". ";
}
ol ol ol ol ol ol ol ol > li:before {
  content: counter(mylist, lower-alpha) ". ";
}
ol ol ol ol ol ol ol ol ol > li:before {
  content: counter(mylist, lower-roman) ". ";
}


.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}


.ql-video {
  display: block;
  max-width: 100%;
}
.ql-video.ql-align-center {
  margin: 0 auto;
}
.ql-video.ql-align-right {
  margin: 0 0 0 auto;
} */

.Arabic .catgoryproductouter .slick-slider {
  direction: rtl;
}

.coupon_code {
  width: 100%;
}

.coupon_code .popup_rating {
  display: flex;
  justify-content: space-between;
}

/* 18.04.2022 */
.col-12.col-sm-4.col-md-5 span {
  font-weight: 500;
  line-height: 1.8;
}

.col-12.col-sm-4.col-md-5 li {
  font-weight: 500;
}

.package-flex-sec {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}


.individual-section {
  width: 30%;
  background: #fff;
  border: 1px solid;
  border-radius: 25px;
  margin-bottom: 50px;
}

/* corporate style */
.corporate-individual-section {
  width: 30%;
  /* background: #fff; */
  /* border: 1px solid; */
  /* border-radius: 25px; */
  margin-bottom: 50px;
  /* box-shadow: 0px 4px 4px 4px grey; */
  /* margin-right: 37px */
}

h3.package-title {
  color: #145388;
  text-align: center;
  padding-top: 4px;
  font-size: 24px;
  word-break: break-all;
}

.pack-sec1 li {
  text-align: center;
  padding-top: 10px;
  font-weight: 600;
  font-size: 18px;
}

.price-section {
  display: flex;
  justify-content: center;
}

.package-but-sec {
  padding: 20px 0;
  padding-bottom: 35px;
}

.package-pieces-count {
  text-align: center;
  padding-top: 10px;
  font-size: 25px;
  font-weight: 500;
}

.price-section p {
  font-weight: 700;
  color: #145388;
  font-size: 32px;
  margin-bottom: 0px;
}

.package-defined-pieces {
  text-align: center;
  padding-bottom: 12px;
}

.package-design-button-sec {
  width: 60%;
  background-color: #f2f2f2;
  margin: auto;
  position: relative;
  bottom: 26px;
  border-radius: 10px;
}

.corporate-package-design-button-sec {
  width: 60%;
  /* background-color: #f2f2f2; */
  margin: auto;
  /* position: relative; */
  bottom: 26px;
  border-radius: 10px;
}


.price-section sup {
  color: #145388;
  font-weight: 500;
  font-size: 13px;
  top: 0px;
}

.package-info li {
  background: none;
}

.pack-sec {
  /* margin-left: 18px; */
  display: flex;

}

.pack-sec i.fa.fa-check {
  color: #145388;
}

.pack-sec li {
  color: #7a7a7a;
  font-size: 16px;
  margin: 0px;
}

.package-display-section {
  margin-bottom: 50px;
  /* margin-left: 3% */
}

.package-info {
  margin-bottom: 25px;
  margin-top: 20px;
}

input.package-btn1 {
  border: 1px solid #145388;
  color: #fff;
  background: #145388;
  padding: 10px 20px;
  border-radius: 25px;
}

.package-sub-cat {
  text-align: center;
  padding-top: 10px;
  font-size: 20px;
  font-weight: 500;
}

.fa-check:before {
  top: 7px;
  position: relative;
}

.package-button {
  text-align: center;
  margin-bottom: 25px;
  position: relative;
  top: 45px;
}

.corporate-package-button {
  text-align: center;
  margin-bottom: 25px;
  /* position: relative;
  top: 45px; */
}

input.package-btn {
  border: 1px solid;
  color: #fff;
  padding: 12px 18px;
  border-radius: 12px;
}


input.all-package-btn {
  position: relative;
  top: 10px;
}

.package-sec-title {
  font-size: 24px;
  font-weight: 400;
  color: #145388;
  width: 85%;
}

.Promotional_pack_sec {
  margin: 40px 0px;
  display: flex;
}

input.all-package-btn {

  color: #145388;
  border: none;
  background: none;
}

.all-package-button:after {
  content: ">>";
  position: relative;
  top: 10px;
}

.pack-sec li {
  padding: 5px 5px;
  border-radius: 5px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 70px;
}

section.vendor_list_detail_sec.py-11.py-md-11 {
  padding-left: 4%;
}

.package-name {
  font-size: 18px;
  font-weight: 600;
  width: 50%
}

.promo-pack-flex {
  display: flex;
  width: 50%;
  min-height: 42px;
}

.package-information {
  position: relative;
  top: 5px;
  width: 73%;
  line-height: 1.6;

}

.promo-pack-details {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.package-laundry-name {
  display: flex;
  margin: 25px 0;
}

h4.promotional-package-shop-name {
  font-weight: 500;
}

h5.promotional-package-shop-name {
  position: relative;
  margin-left: 13px;
  font-size: 18px;
}

.promo-buy-now {
  margin-top: 4%;
  margin-bottom: 2%;
}

.package-book-service-sec {
  width: 20%;
  margin: auto;
}

.customer-page.list-sec {
  background-color: #fff;
  box-shadow: 3px 0px 7px 0px rgb(0 0 0 / 16%);
  margin-right: 40px;
  /* margin-bottom: 4%; */
}

.promo-list-section-title ul {
  display: flex;
  width: 100%;
}

.promo-list-section-title ul li {
  width: 20%;
  text-align: center;
  font-weight: 700;
  padding: 12px 0px;
}

.promo-list-section-title {
  margin-top: 4%;
}

.promo-list-sec-flex {
  display: flex;
  width: 100%;
  padding-bottom: 40px;
}


/* customer corporate package list page  */

.corporate-list-sec-flex {
  text-align: center;
}

.promo-lst-pack-comon-sec {
  width: 20%;
  text-align: center;
  cursor: pointer;
}

.individual-section1 {
  width: 30%;
  background-color: #fff;
  margin-right: 35px;
  background-clip: border-box;
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 13%);
  margin-bottom: 20px;
}

.wallet-page-display-section .col-12.col-md-6 {
  display: flex;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.wallet-field {
  width: 30%;
  margin-right: 20px !important;
}

.wallet-field input {
  border: 1px solid #2f7dc9;
  border-radius: 0px !important;
}

.wallet-page-display-section .col-12.col-md-6 label {
  position: relative;
  top: 10px;
  margin-right: 30px;
}

p.button-section {
  border: 1px solid #2f7dc9;
  padding: 10px 35px;
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 5%;
  color: #2f7dc9;
  font-weight: 500;
}

.amount-button-section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 5%;
}

.wallet-display-bag-price-sec {
  display: flex;
  width: 100%;
}

h1.page_title.mb-5 {
  width: 50%;
}

.bag-sec-block-sec {
  width: 50%;
  text-align: end;
  padding-right: 10%;
  display: flex;
  justify-content: end;
}

input.button-section {
  border: 1px solid #2f7dc9;
  padding: 10px 35px;
  margin-right: 20px;
  margin-top: 30px;
  color: #2f7dc9;
  font-weight: 500;
  width: 11%;
  caret-color: transparent;
  cursor: pointer;
}

i.fas.fa-shopping-bag {
  padding-right: 10px;
}

h3.promotional-package-title1 {
  text-align: center;
  margin-top: 15%;
}

.package-detail-include {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.individual_sec {
  display: flex;
}

.package-but-sec {
  padding: 20px 0;
  padding-bottom: 35px;
  border-radius: 25px 25px 0 0;
  min-height: 173px;
}

.package-detail-sec {
  margin-bottom: 20px;
  min-height: 155px;
}

.individual-section {
  margin-right: 37px;
}

.vendor_list_detail_sec .individual-section {
  margin-left: 20px;
  margin-right: 20px;
}

form.applycoupon .form-group {
  margin-bottom: 0px;
  padding: 0;
}

form.applycoupon {
  width: 45%;
}

.coupon_row.row {
  justify-content: right;
  margin-right: 0px;
  align-items: center;
}

.lable_tag_vendor ul div {
  display: flex;
  align-items: center;
}

/* .lable_tag_vendor li{
  width:36%;
  text-align: center;
} */
.lable_tag_vendor.new li {
  width: auto;
}

.lable_tag_vendor.new {
  text-align: end;
}

.lable_tag_vendor.newdiv li {
  width: 30%;
  text-align: center;
}

.lable_tag_vendor.new .freedelivery,
.lable_tag_vendor.newdiv .freedelivery {
  color: white;
  font-weight: bold;
  background: #1F881F;
  margin-left: 20px;
}

.lable_tag_vendor.new .discount,
.lable_tag_vendor.newdiv .discount {
  margin-left: 20px;
  color: white;
  font-weight: bold;
  background: #8A732D;
}

.lable_tag_vendor.new .discount_subcategory,
.lable_tag_vendor.newdiv .discount_subcategory {
  margin-left: 20px;
  color: white;
  font-weight: bold;
  background: darkorange;
}

.lable_tag_vendor.new .pacakge,
.lable_tag_vendor.newdiv .pacakge {
  color: white;
  font-weight: bold;
  background: #E94D63;
  margin-left: 20px;
}

.lable_tag_vendor.newdiv span {
  width: 51%;
  position: relative;
  bottom: 6px;
}

.usedCount {
  width: 48%;
  position: relative;
  top: 8px;
  right: 30px;
  color: red;
}

.pack-count {
  position: relative;
  top: 4px;
  padding-left: 10px;
  color: red;
}

.package-content-sec {
  position: relative;
  top: 4px;
}

.package-title-sec {
  width: 50%;
  font-weight: 700;
}

.package-order-deta-flex {
  display: flex;
  width: 50%;
  min-height: 40px;
}

.package-detail {
  margin-left: 2%;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 4%;
}

/* 23.06.2022 */
.wallet-page-display-section {
  border: 1px solid #00000012;
  box-shadow: 3px 0px 7px 0px rgb(0 0 0 / 16%);
  padding: 19px 20px;
  border-radius: 19px;
  background-color: #ffffff;
}

.wallet-history-flex {
  display: flex;
  width: 100%;
  min-height: 80px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
}

.wallet-date {
  width: 45%;
  display: flex;
}

.wallet-amount-credit {
  width: 45%;
  display: flex;
}

.wallet-date-sec {
  color: #307dc9;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.8;
  text-align: center;
  width: 50%;
}

.wallet-order-id {
  font-size: 18px;
  font-weight: 400;
  color: #7a7a7a;
  line-height: 1.8;
  text-align: center;
  width: 50%;
}

.wallet-order-id span {
  font-size: 15px;
}

.wallet-amount-sec {
  width: 50%;
  text-align: center;
  line-height: 1.8;
  font-weight: 400;
}

.wallet-credit-debit {
  width: 50%;
  color: green;
  font-weight: 400;
  text-align: center;
  line-height: 1.8;

}

.wallet-filter-date-sec {
  margin-bottom: 5%;
  clear: both;
}

.coupon_row.row p {
  text-align: center;
  font-weight: 500;
  margin-bottom: 0px;
}

.payment_section {
  width: 50%;
}

.balance_amount_section {
  margin-top: 25px;
}

.balance_amount_flex {
  display: flex;
  width: 50%;
  min-height: 30px;
}

.balance_amount_flex1 {
  display: flex;
  width: 82%;
  min-height: 30px;
  padding-top: 1%;
}

.balance_amount_title {
  width: 50%;
  font-weight: 400;
  font-size: 18px;
}

.balance_wallet_amount {
  /* width: 50%; */
  font-size: 18px;
}

.wallet-credited-debit {
  width: 50%;
  color: green;
  font-weight: 400;
  text-align: center;
  line-height: 1.8;
}

.wallet-credit-debited {
  width: 50%;
  color: red;
  font-weight: 400;
  text-align: center;
  line-height: 1.8;
}

.wallet-error-message {
  color: #c21515;
  font-size: 15px;
  padding-left: 17%;
  font-weight: 300;
}

/* 13.07.2022 */
.promotion-pack-sec {
  margin-bottom: 25px;
}

.promotion-pack-sec span {
  background-color: #E94D63;
  padding: 10px 20px;
  color: #ffff;
  border-radius: 4px;
}

li.package {
  background-color: #E94D63;
  color: #ffff;
  font-weight: bold;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
}

.package-display-section .individual-section {
  width: 27%;
}

.add_more_section {
  display: flex;
  justify-content: center;
}

.add_more_section center {
  margin: 5px;
}

li.viewdetails {
  background: none;
}

.Package-Service-List {
  /* position: absolute; */
  /* right: 0px; */
}

.MangageList-Package {
  display: flex;
  width: 100%;
}

.Package-Service-List1 {
  padding-top: 1%;
  padding-bottom: 5%;
}

.Package-Service-List1 p {
  position: absolute;
  /* right: 5%; */
}

p.balance-wallet {
  padding-right: 10px;
}

/* package hide */
/* li.nav-item:nth-child(7) {
  display: none;
} */
/* .lable_tag_vendor.newdiv .discount {
  margin-left: 20px;
} */
.wallet_amount_deduction .close {
  cursor: pointer;
  position: relative;
  left: 2px;
}

p.transaction_history {
  text-align: center;
  padding-top: 1%;
  font-weight: 300;
  font-size: 18px;
  color: #000;
}

.lable_tag_vendor.newdiv.servicelist {
  display: flex;
}

@media screen and (max-width:1023px) {
  .usedCount {
    right: 10px;
  }
}

li.discount_subcategory {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
  background: #8A732D;
}

html body .iMnhxj .selected a {
  color: black !important;
  font-weight: bold;
}

.list_notification.offers li {
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  background-color: #ffffff;
}

.wallet-payment-stus {
  font-weight: 400;
  text-transform: capitalize;
}

.Free-delivery-timings-section {
  display: flex;
}

.free-delivery-offer-days {
  width: 30%;
  font-size: 15px;
  line-height: 1.8;
  font-weight: 400;
}

.free-delivery-offer-timings {
  font-size: 15px;
  line-height: 1.8;
  color: #2f7dc9;
  font-weight: 500;
  padding-bottom: 10px;
}

span.themeColor.discount,
.subcategory_discount {
  color: red !important;
}

span.discount_subcategory.en {
  display: block;
  position: absolute;
  top: 12px;
  right: 0px;
  color: darkorange;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}

span.discount_subcategory.ar {
  display: block;
  position: absolute;
  top: 12px;
  left: 10px;
  color: darkorange;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}

span.discount_subcategory_new {
  display: block;
  position: absolute;
  color: darkorange;
  text-transform: uppercase;
  font-weight: bold;
  right: 31px;
}

.subcategory_discounts span.en {
  display: block;
  text-align: right;
  padding: 2px;
  color: darkorange;
  font-weight: bold;
  font-size: 13px;
}

.subcategory_discounts span.ar {
  display: block;
  text-align: left;
  padding: 2px;
  color: darkorange;
  font-weight: bold;
  font-size: 13px;
}

.lable_tag_vendor.new li {
  margin-left: 0px !important;
}

.sub_discountcategory {
  order: 2;
}

.checkoutadd p {
  padding: 2px;
}

.subcategory_alignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span.coupon_used_times {
  display: flex !important;
}

span.coupon_used_times p {
  position: relative;
  top: 2px;
  padding-left: 5px;
  color: red;
  font-weight: 500;
}

.free-delivery-offer-days {
  width: 36%
}

ul.sidebar_nav.d-block.w-100 {
  height: calc(100% - 100px);
  background: white;
  z-index: 3;
  position: fixed;
  transition: transform 300ms;
  padding-top: 10px;
  padding-bottom: 10px;
  left: 0;
  width: 18.21rem !important;
  overflow: scroll;
}

@media screen and (min-width:1280px) and (max-width:1400px) {
  ul.sidebar_nav.d-block.w-100 {
    height: 420px;
  }

  span.text-break {
    min-width: 100px !important;
    word-break: break-all;
  }
}

@media screen and (min-width:1280px) and (max-width:1535px) {
  body.Arabic .overlay-ribbon:after {
    top: 14.5px !important;
  }
}

p.first {
  width: 80%;
}

.flex_row {
  display: flex;
}

.flex_row.subtotal p {
  font-weight: bold !important;
}

.overlay-ribbon:after {
  content: "";
  position: relative;
  right: 0;
  top: -14.96px;
  left: 3px;
  width: 0;
  height: 0;
  border-right: 1.75em solid transparent;
  border-bottom: 1.5em solid #007fcf;
}

.overlay-ribbon:before {
  content: "";
  position: relative;
  left: 100%;
  top: 15.5px;
  border-top: 1.5em solid #007fcf;
  border-right: 1.5em solid transparent;
}

.overlay-ribbon {
  width: 170px;
  position: relative;
  left: 2%;
  background: #007fcf;
  padding: 8px 0;
}

.overlay-ribbon span {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.overlay-ribbon span a {
  color: #fff;
}



/* corporate ribbioin */
.overlay-corporate-ribbon {
  width: 220px;
  position: relative;
  left: 2%;
  background: #007fcf;
  padding: 8px 0;
  margin-bottom: 7px;
}

.Arabic .overlay-corporate-ribbon {
  left: -2%;
}

.overlay-corporate-ribbon span {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.overlay-corporate-ribbon span a {
  color: #fff;
}


.overlay-corporate-ribbon:after {
  content: "";
  position: relative;
  right: 0;
  top: -14.96px;
  left: 11px;
  width: 0;
  height: 0;
  border-right: 1.75em solid transparent;
  border-bottom: 1.5em solid #007fcf;
}

.overlay-corporate-ribbon:before {
  content: "";
  position: relative;
  left: 100%;
  top: 15px;
  border-top: 1.5em solid #007fcf;
  border-right: 1.5em solid transparent;
}



@media screen and (min-width: 768px) and (max-width: 1279px) {
  .overlay-ribbon:after {
    top: -12.96px
  }

  .overlay-ribbon:before {
    top: 10.25px;
  }

  .overlay-corporate-ribbon:after {
    top: -12.96px
  }

  .overlay-corporate-ribbon:before {
    top: 10.25px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1920px) {
  .overlay-ribbon:after {
    left: 2px;
  }

  .overlay-ribbon:before {
    left: 99%;
  }


  .overlay-corporate-ribbon:after {
    left: 2px;
  }

  .overlay-corporate-ribbon:before {
    left: 99%;
  }
}



span.themeColor.discount_latest {
  color: red;
  width: 100px;
}

.wallet_amount_deduction span {
  padding-left: 10px;
  top: -2px;
}

.wallet_amount_deduction {
  display: flex;
}

p.vendor_cart_name {
  font-size: 25px;
  color: #414141;
  margin-bottom: 1.5rem !important;
  font-weight: 400;
}

.promo-lst-pack-comon-sec.package_cls1 {
  width: 15% !important;
}

.promo-lst-pack-comon-sec.package_cls2 {
  width: 15% !important;
}

.promo-lst-pack-comon-sec.package_cls3 {
  width: 27% !important;
}

.promo-lst-pack-comon-sec.package_cls4 {
  width: 27% !important;
}

.promo-lst-pack-comon-sec.package_cls5 {
  width: 16%;
}

/* corporate list package row each fields width */
.corporate-lst-pack-comon-sec.package_cls1 {
  width: 20% !important;
}

.corporate-lst-pack-comon-sec.package_cls2 {
  width: 20% !important;
}

.corporate-lst-pack-comon-sec.package_cls3 {
  width: 25% !important;
}

.corporate-lst-pack-comon-sec.package_cls4 {
  width: 25% !important;
}


p.package_expired {
  color: red;
  font-weight: 400;
  padding-top: 5px;
  margin-bottom: 0px;
}

h3.promotional-package-title span {
  color: red;
  font-size: 21px;
}

body.Arabic ul.sidebar_nav.d-block.w-100 {
  position: relative;
}

body.Arabic .overlay-ribbon:before {
  content: "";
  position: relative;
  left: -100%;
  top: -15px;
  border-bottom: 1.5em solid #007fcf;
  border-left: 1.5em solid transparent;
  border-top: none !important;
  border-right: none !important;
}

body.Arabic .overlay-ribbon:after {
  content: "";
  position: relative;
  right: 50px;
  top: 15.5px;
  left: 44px;
  width: 0;
  height: 0;
  border-left: 1.75em solid transparent;
  border-top: 1.5em solid #007fcf;
  border-right: none !important;
  border-bottom: none !important;
}

body.Arabic .overlay-corporate-ribbon:before {
  content: "";
  position: relative;
  left: -100%;
  top: -15px;
  border-bottom: 1.5em solid #007fcf;
  border-left: 1.5em solid transparent;
  border-top: none !important;
  border-right: none !important;
}

body.Arabic .overlay-corporate-ribbon:after {
  content: "";
  position: relative;
  right: 50px;
  top: 15.04px;
  left: 44px;
  width: 0;
  height: 0;
  border-left: 1.75em solid transparent;
  border-top: 1.5em solid #007fcf;
  border-right: none !important;
  border-bottom: none !important;
}

body.Arabic .overlay-ribbon {
  left: -2%;
}

.Pickup_Validation_button a {
  color: #fff;
  background-color: #348ad2;
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 3px;
}

span.btn.btn-sm.btn-secondary.reorder {
  line-height: 0.4;
  padding: 15px;
  background: green;
}

span.btn.btn-sm.btn-secondary.reorder:hover {
  color: white;
}

.express_delivery_line hr {
  border-top: 2px solid #afcde0;
  box-shadow: 0 1px 11px #bbb;
}

.package-tag-section {
  position: absolute;
  color: #fff;
}

.packageFreedelivery p {
  font-size: 15px;
  font-weight: 400;
  color: #238429;
}

.cancel_express_delivery {
  background: #00c2ff !important;
  border: 1px solid #00c2ff !important;
}

.cancel_express_delivery:hover {
  color: white !important;
}

.custom-text-display p {
  padding: 10px 10px;
  line-height: 1.4;
  color: rgb(51, 153, 153);
}

/* Tooltip css start */

.schedule_type_icon i.fas.fa-info {
  background: rgb(47, 125, 201);
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 15px;
  padding-left: 6px;
  height: 15px;
  padding-top: 4px;
  font-size: 7px;
  position: absolute;
  right: -20px;
  top: -13px;
  font-weight: 501;
}

.tooltiptext.dark.tooltip-top::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.tooltipStyleChange {
  color: #006080
}

/**/

.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  z-index: 0;
  /*border-bottom: 1px dotted #ccc;
    color: #006080; */
}

.tooltip .tooltiptext.light {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid rgba(0, 0, 0, .2);
}

.tooltip .tooltiptext.dark {
  background-color: #555555;
  color: #ffffff;
}

.tooltip .tooltiptext {
  text-align: left;
  visibility: hidden;
  position: absolute;
  width: 500px;
  padding: 5px;
  border-radius: 6px;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0 2px 6px rgb(245 253 254);
  height: 325px;
  overflow-y: scroll;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-right {
  top: -5px;
  left: 125%;
}

.tooltip-right::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

.tooltip-bottom {
  top: 135%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-bottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 11px;
  border-style: solid;
  border-color: transparent transparent #55555530 transparent;
}

.tooltip-top {
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip-left {
  top: -5px;
  bottom: auto;
  right: 128%;
}

.tooltip-left::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #555;
}

/* Tooltip css end */
li.corporate-package {
  /* background-color: #C0CA33; */
  background-color: #941100;
  color: #ffff;
  font-weight: bold;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
}

.lable_tag_vendor.newdiv li.corporate-package {
  width: 40%;
  margin-left: 20px;
}

.easy-pickup-close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 30px;
  color: red;
}

.Arabic .easy-pickup-close {
  left: 20px;
  right: auto;
}

.corporate-details-sec {
  box-shadow: 2px 2px 2px lightgrey;
  width: 87%;
  margin: 0px 23px;
  position: relative;
  top: 8px;
  border-radius: 13px;
  /* left: 21px; */
}

.Arabic .corporate-details-sec {
  left: 1px;
  /* right: 22px; */
}

.Arabic .package-detail {
  padding-right: 1.5%;
}

.easy-pickup-cancel {
  line-height: 25px;
  font-size: 17px;
  margin-top: 7%;
}

.Arabic .easy-pickup-transport-cancel {
  display: flex;
  flex-direction: column;
}

.Arabic p.esy-pckip-tspt {
  margin: 8px !important;
}

.modal-manual-header button.close:after,
.modal-manual-header button.close:after {
  color: #fff;
  font-size: 18px;
  background: hsla(0, 0%, 47.8%, .48);
  padding: 5px 14px;
  border-radius: 15%;
  letter-spacing: 1px;
  font-weight: 400;
  background: #3F51B5;
}

.customer-transportation-details {
  /* padding-left: 15px; */
  padding-top: 5px;
}

span.with-transportation {
  font-size: 20px;
  color: #16980c;
  font-weight: 500;
}

span.without-transportation {
  font-size: 20px;
  color: #ed1e0b;
  font-weight: 500;
}

.corporate-list-pg .sc-bdVaJa.iMnhxj:first-child {
  background: #5c95eb;
  color: #fff;
  font-weight: 700;
  padding: 4px 0px;
}

.promo-list-section-title ul {
  color: #000;
}

.promo-list-pack {
  color: #000;
  font-weight: 300;
}

.corporate-lst-pack-comon-sec.package_cls5 {
  width: 15%;
}

.corporate-lst-pack-comon-sec.package_cls6 {
  width: 15%;
}

.iMnhxj .tab {
  padding: 0px !important;
}

.Arabic .custom-repeate-hd .schedule_type_icon i.fas.fa-info {
  right: 6px;
  top: -10px;
}

.Arabic .custom-repeate-hd .fa-info:before {
  position: relative;
  left: -6.5px;
}

.notification_content i.fas.fa-info {
  font-size: 9px;
  position: absolute;
  right: 15px;
  top: 25px;
}

.notification_content .fa-info:before {
  position: relative;
  left: -1px;
  top: -1px;
}

li.custom-shimmer-section {
  padding-top: 28px;
  padding-bottom: 41px;
}

.shimmer-image-wrapper {
  width: 50px;
  position: relative;
  overflow: hidden;
  background: #f6f7f8;
  height: 50px;
  border-radius: 50%;
}

.shimmer-text-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #f6f7f8;
  height: 25px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.shimmer-description-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #f6f7f8;
  height: 12px;
  border-radius: 4px;
}

.shimmer-description1-wrapper {
  width: 90%;
  position: relative;
  overflow: hidden;
  background: #f6f7f8;
  height: 12px;
  border-radius: 4px;
  margin-left: auto;
}

.shimmer-button-wrapper {
  width: 90%;
  position: relative;
  overflow: hidden;
  background: #f6f7f8;
  height: 50px;
  border-radius: 4px;
  margin-bottom: 9px;
  margin-left: auto;
}

.shimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f6f7f8 25%, #e0e0e0 50%, #f6f7f8 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}


@media screen and (min-width: 1920px) {
  .overlay-ribbon:before {
    top: 14.5px;
  }

  body.Arabic .overlay-ribbon:after {
    top: 14.5px;
  }
}

/* Customer Responsive */
@media screen and (max-width: 767px) {

  /* Vendor Listing page */
  .laundries_desc_data_title {
    margin-left: 0px !important;
  }

  .branch_title {
    margin-left: 0px
  }

  .laundries_rates {
    text-align: right;
  }

  .lable_tag_vendor.new li {
    width: 100%;
    text-align: center;
  }

  .lable_tag_vendor.new {
    padding-top: 10px;
  }

  .package-book-service-sec .display_flex {
    flex-wrap: wrap;
  }

  .package-book-service-sec {
    width: 50%;
  }

  .view_all_laundries_btn {
    padding: 10px 0px !important;
    margin-right: 0px !important;
  }

  .custom-address-btn {
    width: 60% !important;
  }

  .manage_account_user_sec .display_flex {
    flex-wrap: wrap;
  }

  .tooltip .tooltiptext {
    width: 300px;
    height: 200px;
  }

  .tooltip-top {
    left: 0px;
    margin-left: 0px;
  }

  .venderdetailsright.w-100.pl-0 {
    padding-bottom: 10px;
  }

  .applicable_vendor {
    font-weight: 600;
  }

  .applicable_timing {
    font-weight: 600;
  }

  .custom_cart_cls {
    display: block !important;
  }

  .custom_clear_cart {
    float: right;
  }

  .custom_coupon_cls {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 3%;
  }

  .custom_service_list_cat {
    display: block;
  }

  .catgoryproductouter .slick-track {
    width: 100% !important;
    display: flex;
  }

  .catgoryproductouter .slick-slide {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
  }

  .custom_service_list_cat .col-md-6 {
    width: 90%;
    margin: auto;
  }

  form.applycoupon {
    width: 50%;
  }

  .coupon_row .col-12 {
    margin-bottom: 6px;
    float: right;
    padding-right: 0px;
  }

  .coupon_row button {
    width: 89%;
  }

  .coupon_row p {
    float: right;
    font-weight: 500;
  }

  .order_tabs div a {
    min-width: 100%
  }

  button.btn-reorder {
    margin-right: 9px;
    padding: 10px 20px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .custom-page-tit-phem {
    width: 100% !important;
    margin-bottom: 15px !important;
    margin-top: 20px !important;
  }

  .custom-free-delivery-cls {
    width: 100%;
    padding-left: 0px;
  }

  .timing_sec.text-center {
    float: right;
    align-items: end;
    display: flex;
    flex-direction: column;
  }

  .custom_validity_cls {
    display: none;
  }

  .wallet-payment-stus {
    display: none;
  }

  .wallet-date {
    width: 50%;
    flex-direction: column-reverse;
  }

  .wallet-order-id span {
    display: none;
  }

  .wallet-order-id {
    font-size: 14px;
  }

  .wallet-date-sec {
    font-size: 12px;
    letter-spacing: normal;
    width: 80%;
    text-align: left;
  }

  .wallet-amount-credit {
    width: 50%;
    align-items: end;
    display: flex;
    flex-direction: column;
  }

  .wallet-history-flex {
    align-items: center;
  }

  .wallet-display-bag-price-sec h1.page_title {
    margin-bottom: 20px !important;
  }

  .wallet-display-bag-price-sec {
    margin-top: 20px;
  }

  .wallet-filter-date-sec .col-6 {
    padding: 10px 5px;
    max-width: 33.333%;
    display: flex;
    flex-wrap: wrap;
  }

  .wallet-mobile {
    display: block;
    float: right;
  }

  .wallet-desktop {
    display: none !important;
  }

  .wallet-mobile.row .col-6 {
    max-width: 100%;
  }

  .package-display-section .individual-section {
    width: 100%;
  }

  .all-package-button {
    width: 30%;
  }

  .promo-pack-flex {
    width: 100%;
  }

  .package-name {
    width: 65%;
    font-size: 15px;
  }

  .payment_section {
    width: 100%
  }

  .package-information {
    font-size: 15px;
  }

  .promotions-package-view-package .copuncode {
    width: 100% !important;
  }

  .promotions-package-view-package .copuncode .d-flex {
    flex-direction: row;
  }

  .balance_amount_flex {
    width: 100%;
  }

  .promo-list-section-title {
    display: none;
  }

  .customer-page.list-sec {
    background-color: rgb(245, 253, 254);
    box-shadow: none;
    margin-right: 0px;
  }

  .promo-list-pack {
    display: none;
  }

  .package-information {
    line-height: 0.8;
  }

  .package-data-spec {
    background-color: #fff;
    box-shadow: 3px 0px 7px 0px rgb(0 0 0 / 16%);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-right: 6px;
  }

  .catgoryproductouter .slick-track {
    transform: inherit !important;
  }

  .vendor_list_detail_sec .daycheck_type_list_sec {
    margin: 0px;
  }

  .custom_service_list_cat.row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .customer-corporate-package-list-main li {
    width: 33.333%;
    text-align: center;
  }

  .customer-corporate-package-list-main {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  p.coporate-customer-key-details {
    font-weight: 500;
    color: #000;
    margin-bottom: 0px;
    font-size: 15px !important;
  }

  .corporate-pack-list-sec {
    width: 90%;
    background-color: #fff;
    box-shadow: 3px 0px 7px 0px rgb(0 0 0 / 16%);
    margin: auto;
  }

  .coporate-customer-key-details-main {
    width: 100%;
    max-width: 100%;
  }

  .venderdetailsright.corporatepackagedetail {
    padding: 0px !important;
    width: 100%;
  }

  .venderdetailsright p span {
    min-width: auto;
    width: 50%;
  }

  .custom-repeated-form-cls div {
    width: 40%;
    margin-right: 0px;
  }

  .custom-repeate-hd {
    padding: 10px 20px !important;
  }

  .custom-repeated-form-cls .margin-right.positions-relative {
    margin-right: 0px !important;
    margin-bottom: 10px !important;
  }

  .custom-repeate-hd .tooltip .tooltiptext {
    width: 275px;
    height: 200px;
    left: -110px;
  }

  form.login_signup_form.custom_email_phn_cls .mt-4 {
    margin-top: 0px !important;
    margin-bottom: 20px;
  }

  .list_notification.offers li {
    padding: 8px 13px !important;
  }

  span.discount_subcategory.en {
    top: 0px;
  }

  .Free-delivery-timings-section {
    width: 100%;
  }

  .free-delivery-offer-days {
    width: 50%;
  }

  .notification_content i.fas.fa-info {
    position: relative;
    top: 0px;
  }

  .laundries_desc_data_list li {
    font-size: 1.2rem;
  }

  header#navbar_scroll_fixed {
    height: 73px;
  }

  img.home_banner_img{
    height: 300px;
  }

  .home_banner_desktop{
    display: none;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .laundries_desc_data {
    margin-left: 10px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .laundries_rates {
    min-width: 100px;
  }

  .wallet-mobile.row .col-6 {
    padding-right: 20px;
  }

  body.Arabic .package-tag-section img {
    left: -197px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .laundries_list>li {
    padding: 1.07rem 1rem;
  }

  .laundries_desc_data {
    margin-left: 5px;
  }

  .wallet-mobile.row .col-6 {
    padding-right: 2px;
  }

  .wallet-amount-sec {
    width: 75%;
  }

  body.Arabic .package-tag-section img {
    left: -141px !important;
  }

}

@media screen and (min-width: 320px) and (max-width: 424px) {
  .vendor_list_detail_view .workingtime .d-flex {
    display: block !important;
  }

  .vendor_list_detail_view .workingtime .d-flex .px-2 {
    display: none;
  }

  .vendor_list_detail_view .workingtime .d-flex span {
    line-height: 1.8;
  }

  .vendor_list_detail_view .workingtime span.dayname {
    width: 120px;
  }

  .lable_tag_vendor.newdiv li {
    width: 60%;
  }

  .lable_tag_vendor.newdiv span {
    display: none;
  }

  .overlay-ribbon {
    width: 110px;
    left: 0%;
    padding: 8px 0;
  }

  .overlay-ribbon:before {
    top: -5.5px;
  }

  .overlay-ribbon span {
    font-size: 14px;
    font-weight: 500;
  }

  .overlay-ribbon:after {
    top: -11.96px;
    left: -20px;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .lable_tag_vendor.newdiv li {
    width: 40%
  }

  .lable_tag_vendor.newdiv span {
    bottom: 0px;
  }

  .wallet-mobile.row .col-6 {
    padding-right: 37px;
  }

  .package-display-section .individual-section {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .overlay-ribbon {
    left: 0%;
    width: 150px;
  }

  .overlay-ribbon span {
    font-size: 14px;
    font-weight: 500;
  }

  .overlay-ribbon:before {
    top: 10.5px;
  }

  .overlay-ribbon:after {
    left: 6px;
    top: -11.96px;
  }

  section.home_banner{
    padding-top: 14%;
  }

}

@media screen and (min-width: 768px) {
  .wallet-mobile {
    display: none;
  }

  .wallet-desktop {
    display: block;
  }

  .promotional-package-title {
    padding-top: 20px;
  }

  .promo-list-pack-mobile {
    display: none;
  }
  .home_banner_mobile{
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .shimmer-image-wrapper {
    width: 85%;
    height: 95%;
  }

  .custom-shimmer-section .laundries_desc_data {
    width: 100%;
  }

  .custom-shimmer-section .laundries_desc_data_list li {
    margin: 0px;
  }

  .shimmer-text-wrapper {
    width: 30%
  }

  .custom-shimmer-section ul.laundries_desc_data_list {
    width: 40%;
  }

  .shimmer-description1-wrapper {
    margin-bottom: 10px;
  }

  li.custom-shimmer-section{
    padding-bottom: 52px;
  }
}

.add_more_section a {
  border-radius: .25rem;
  font-size: 1.21rem;
  padding: .85rem 2rem;
}

.sidebar_nav li:hover a,
.sidebar_nav li.active a {
  background-color: var(--primary-color);
}